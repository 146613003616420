import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

//LevelType
//post functions
export const addLeveLType = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_LEVELTYPE, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}

//get functions
export const getAllLevelTypes = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_LEVELTYPES}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getLevelTypeById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET__LEVELTYPES_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateLevelType = async (payload, token, levelTypeId) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_LEVELTYPE}/${levelTypeId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteLeveLType = async (token, levelTypeId,) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_LEVELTYPE}/${levelTypeId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}

//Levels
//post functions
export const addLeveL = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_LEVEL, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}

//get functions
export const getAllLevels = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_LEVELS}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getLevelById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET__LEVEL_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateLevel = async (payload, token, levelId) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_LEVEL}/${levelId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteLeveL = async (token, levelId) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_LEVEL}/${levelId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}

//Units
//post functions
export const addUnit = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_UNIT, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}
export const uploadContent = async (formData, subTopicId, token) => {
  return await axios
    .post(`${getApi.SETUP.UPLOAD_CONTENT}/${subTopicId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },

      onUploadProgress: (progressEvent) => {
        const percentComplete = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(`Upload progress: ${percentComplete}%`);
        // You can update your progress bar or UI here
      },

    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getAllContents = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_CONTENTS}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const deleteContent = async (token, contentId) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_CONTENT}/${contentId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}

//get functions
export const getAllUnits = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_UNITS}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getUnitById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET__UNIT_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateUnit = async (payload, token, unitId) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_UNIT}/${unitId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteUnit = async (token, unitId) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_UNIT}/${unitId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}

//Topics
//post functions
export const addTopic = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_TOPIC, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}



//get functions
export const getAllTopics = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_TOPICS}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getTopicById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET__TOPIC_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateTopic = async (payload, token, topicId) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_TOPIC}/${topicId}`, payload, {

      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteTopic = async (token, topicId) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_TOPIC}/${topicId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}

//SubTopics
//post functions
export const addSubTopic = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_SUB_TOPIC, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}



//get functions
export const getAllSubTopics = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_SUB_TOPICS}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getSubTopicById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET__SUB_TOPIC_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateSubTopic = async (payload, token, topicId) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_SUB_TOPIC}/${topicId}`, payload, {

      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteSubTopic = async (token, subTopicId) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_SUB_TOPIC}/${subTopicId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}








//delete funtions
export const deleteSubCategory = async (subCategoryId, token) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_SUB_CATEGORY}/${subCategoryId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}


//Quizes
//post functions
export const addQuiz = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_QUIZ, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}

//get functions
export const getAllQuizes = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_QUIZES}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getQuizById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET_QUIZ_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateQuiz = async (payload, token, quizId) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_QUIZ}/${quizId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteQuiz = async (quizId, token) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_QUIZ}/${quizId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}

//Test
//post functions
export const addTest = async (payload, token) => {
  return await axios
    .post(getApi.SETUP.ADD_TEST, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}

//get functions
export const getAllTests = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_TESTS}?${params === undefined ? "" : params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getTestById = async (id) => {
  return await axios
    .get(`${getApi.SETUP.GET_TEST_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateTest = async (payload, token, testId) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_TEST}/${testId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteTests = async (testId, token) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_TEST}/${testId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}