import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Container } from "react-bootstrap"
import toast from "react-hot-toast";
import moment from "moment"
import TableTemplate from "components/TableTemplate"

import ExpandableRowComponent from "./ExpandableComponentLevel"
import AddEditLevelType from "./AddEditLevelType"

import * as setup from "models/setup";
import Swal from "sweetalert2";
import DeleteLevelType from "./DeleteLevelType";
const Levels = props => {
  const [levelTypes, setLevelTypes] = useState([])

  //processing variables
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  //modal
  const [showAddEditLevelType, setShowAddEditLevelType] = useState(false)
  const [showDeleteLevelType, setShowDeleteLevelType] = useState(false)

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <button
              className="btn btn-sm text-info text-nowrap "
              onClick={() => {
                setShowAddEditLevelType(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteLevelType(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },

    { name: "LeveL Type", selector: row => row?.levelTypeName },

    {
      name: "Status",
      selector: row =>
        row?.levelTypeStatus === "active" ? "Active" :
          row?.levelTypeStatus === "inactive" ? "Inactive" :
            row?.levelTypeStatus === "suspended" ? "Suspended" : null,

      conditionalCellStyles: [
        {
          when: (row) => row?.levelTypeStatus === 'active',
          style: {
            color: 'green',
          },
        },
        {
          when: (row) => row?.levelTypeStatus === 'inactive',
          style: {
            color: 'orange',
          },
        },
        {
          when: (row) => row?.levelTypeStatus === 'suspended',
          style: {
            color: 'danger',
          },
        },
      ]
    },

    { name: "Created", selector: row => moment(row.created).format('LL'), }


  ]


  const subHeaderNav = (
    <div className="d-flex">

      <button className="btn btn-primary"
        onClick={() => setShowAddEditLevelType(true)}>
        Add Level Type
      </button>
    </div>
  )

  const deleteLevel = () => {
    setup.deleteLeveL(token, selected?.id)
      .then(resp => { })
      .catch(err => {

      })
  }

  const getAllLevelTypes = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setIsLoaded(false)
    setup.getAllLevelTypes(params, token)
      .then(resp => {
        const data = resp.levelTypes
        setLevelTypes(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }
  useEffect(() => {
    getAllLevelTypes()
  }, [])


  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <Card.Header>
            <Card.Title className="">Level Type</Card.Title>
          </Card.Header>
          <Card.Body>
            <TableTemplate
              columns={columns}
              rows={levelTypes}
              dataLoaded={isLoaded}
              subHeaderNav={subHeaderNav}
              expandableRows={true}
              expandableRowsComponent={ExpandableRowComponent}
            />

            <AddEditLevelType
              showModal={showAddEditLevelType}
              setShowModal={setShowAddEditLevelType}
              toEdit={toEdit}
              setToEdit={setToEdit}
              selected={selected}
              setSelected={setSelected}
              fetchData={getAllLevelTypes}

            />
            <DeleteLevelType
              showModal={showDeleteLevelType}
              setShowModal={setShowDeleteLevelType}
              selected={selected}
              setSelected={setSelected} />
          </Card.Body>
        </Card>
      </Container>

    </div>
  )
}

Levels.propTypes = {}

export default Levels