import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap"
import moment from "moment"
import toast from "react-hot-toast";

import TableTemplate from "components/TableTemplate"

import * as setup from "models/setup";
import * as lecture from "models/lectures";

import AddEditLectures from "./AddEditLectures"

const Lectures = props => {
  const [lectures, setLectures] = useState()

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)
  const [levelTypes, setLevelTypes] = useState([""])
  const [showAddLecture, setShowAddLecture] = useState(false)
  const [showDeleteLecture, setShowDeleteLecture] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            {/* <Link
              className="btn btn-sm text-primary text-nowrap "
              to={`/user-profile/${row?._id}`}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                View
              </p>
            </Link> */}
            <button
              className="btn btn-sm text-info text-nowrap "
              onClick={() => {
                setShowAddLecture(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteLecture(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },

    { name: "LectureName", selector: row => row?.lectureName },

    {
      name: "Status",
      selector: row =>
        row?.userStatus === "active" ? "Active" :
          row?.userStatus === "inactive" ? "Inactive" :
            row?.userStatus === "suspended" ? "Suspended" : null, filterable: true, sortable: true,

      conditionalCellStyles: [
        {
          when: (row) => row?.userStatus === 'active',
          style: {
            color: 'green',
          },
        },
        {
          when: (row) => row?.userStatus === 'inactive',
          style: {
            color: 'orange',
          },
        },
        {
          when: (row) => row?.userStatus === 'suspended',
          style: {
            color: 'danger',
          },
        },
      ]
    },
    { name: "Start time", selector: row => moment(row.startTime).format('MMMM Do YYYY, h: mm: a'), },

    { name: "Created", selector: row => moment(row.created).format('LL'), }


  ]

  const getAllLectures = (params) => {
    lecture.getAllLectures(params)
      .then(resp => {
        const data = resp?.lectures
        setLectures(data)
        setIsLoaded(true)
      }).catch(err => {

      })
  }
  const getAllLevelTypes = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setup.getAllLevelTypes(params, token)
      .then(resp => {
        const data = resp.levelTypes
        setLevelTypes(data)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    getAllLectures()
    getAllLevelTypes()
  }, [])

  return (
    <div className="page-content">
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title className="text-primary">
            Lectures
          </Card.Title>
          <button className="btn btn-primary"
            onClick={() => setShowAddLecture(true)}
          >
            Add Lecture
          </button>
        </Card.Header>
        <Card.Body>
          <TableTemplate
            dataLoaded={isLoaded}
            rows={lectures}
            columns={columns} />
        </Card.Body>
      </Card>

      <AddEditLectures
        showModal={showAddLecture}
        setShowModal={setShowAddLecture}
        toEdit={toEdit}
        setToEdit={setToEdit}
        selected={selected}
        setSelected={setSelected}
        levelTypes={levelTypes}
        fetchData={getAllLectures}

      />

    </div>
  )
}

Lectures.propTypes = {}

export default Lectures