import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

import * as setup from 'models/setup';

const DeleteTopic = (props) => {
  const profile = JSON.parse(localStorage.getItem('authUser'));
  const token = profile?.token;

  const deleteTopic = () => {
    setup.deleteTopic(token, props.selected?._id)
      .then((resp) => {
        Swal.fire({
          title: 'Deleted!',
          text: 'The Strand has been successfully deleted.',
          icon: 'success',
        });

        // Perform additional actions like closing the modal
        props.setShowModal(false);
        props.setSelected(null);
        props.fetchData()
      })
      .catch((err) => {
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong. Please try again.',
          icon: 'error',
        });
      })
    props.setShowModal(false);
    props.setSelected(null);
    props.fetchData()
  };

  useEffect(() => {
    if (props.showModal) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          deleteTopic(); // Call the delete function after confirmation
        } else {
          props.setShowModal(false); // Close modal if the user cancels
        }
      });
    }
  }, [props.showModal]);

  return null; // This component doesn't render any JSX
};

DeleteTopic.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
};

export default DeleteTopic;
