import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Container, Card, Dropdown } from "react-bootstrap"
import moment from "moment"
import Swal from "sweetalert2";
import toast from "react-hot-toast";

import TableTemplate from "components/TableTemplate"
import AddEditUser from "./AddEditUser";

import * as auth from "models/auth";

const UserManagement = props => {
  const [listedUsers, setListedUsers] = useState([""])

  //processing variables
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  //modal
  const [showAddEditUser, setShowAddEditUser] = useState(false)
  const [showdeleteUser, setShowDeleteUser] = useState(false)

  const filters = [{ name: "", value: "" }]

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <Link
              className="btn btn-sm text-primary text-nowrap "
              to={`/user-profile/${row?._id}`}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                View
              </p>
            </Link>
            <button
              className="btn btn-sm text-info text-nowrap "
              onClick={() => {
                setShowAddEditUser(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteUser(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },

    { name: "Name", selector: row => row?.fullName },
    { name: "Email", selector: row => row?.userEmail },
    { name: "Phone Number", selector: row => row?.phoneNumber },
    {
      name: "Role", selector: row => row?.userType === "super-admin" ? "Super-Admin" : row?.userType === "admin" ? "Admin" : row?.userType === "content-creator" ? "Content Creator" : row?.userType === "content-evaluator" ? "Content Evaluator" : row?.userType === "sales-person" ? "Sales Person" : row?.userType === "publisher" ? "Publisher" : row?.userType === "examiner" ? "Examiner" : row?.userType === "instructor" ? "Instructor" : row?.userType === "marketer" ? "Marketer" : row?.userType === "student" ? "Student" : null, filterable: true,
      sortable: true,
    },
    {
      name: "Status",
      selector: row =>
        row?.userStatus === "active" ? "Active" :
          row?.userStatus === "inactive" ? "Inactive" :
            row?.userStatus === "suspended" ? "Suspended" : null, filterable: true, sortable: true,

      conditionalCellStyles: [
        {
          when: (row) => row?.userStatus === 'active',
          style: {
            color: 'green',
          },
        },
        {
          when: (row) => row?.userStatus === 'inactive',
          style: {
            color: 'orange',
          },
        },
        {
          when: (row) => row?.userStatus === 'suspended',
          style: {
            color: 'danger',
          },
        },
      ]
    },

    { name: "Created", selector: row => moment(row.created).format('LL'), }


  ]

  const subHeaderNav = (
    <div className="d-flex">
      <div>

      </div>
      {/* <div className="d-flex">
        <Dropdown className="me-3">
          <Dropdown.Toggle variant="white" id="dropdown-basic">
            Filter By { }
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {filters.map((item, index) => (
              <div key={index}>
                <Dropdown.Item href="#/action-1">{item?.name}</Dropdown.Item>
              </div>
            ))}

          </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle variant="white" id="dropdown-basic">
            Filter By { }
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {filters.map((item, index) => (
              <div key={index}>
                <Dropdown.Item href="#/action-1">{item?.name}</Dropdown.Item>
              </div>
            ))}

          </Dropdown.Menu>
        </Dropdown>
      </div> */}

    </div>
  )

  const getAllUsers = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    auth.getAllUsers(params, token)
      .then(resp => {
        const data = resp.users
        setListedUsers(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }
  useEffect(() => {
    getAllUsers()
  }, [])
  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <Card.Header className="d-flex justify-content-between">
            <Card.Title className="text-primary">
              User Management
            </Card.Title>
            <button className="btn btn-primary"
              onClick={() => setShowAddEditUser(true)}>
              Add Users
            </button>
          </Card.Header>
          <Card.Body>
            <TableTemplate
              dataLoaded={isLoaded}
              rows={listedUsers}
              columns={columns}
              subHeaderNav={subHeaderNav}
            />

            {/* Modals */}
            <AddEditUser
              showModal={showAddEditUser}
              setShowModal={setShowAddEditUser}
              toEdit={toEdit}
              setToEdit={setToEdit}
              selected={selected}
              setSelected={setSelected}
              fetchData={() => getAllUsers()}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  )
}

UserManagement.propTypes = {}

export default UserManagement