import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner } from "react-bootstrap"
import toast from "react-hot-toast";

import * as setup from "models/setup";

const AddEditLevelType = props => {
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.fetchData()
    props.setToEdit(false)
    props.setSelected(null)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;

    setIsLoading(true)
    const form = event.currentTarget
    const payload = {
      levelTypeName: form.levelTypeName.value,
    }
    if (props.toEdit) {
      setup.updateLevelType(payload, token, props.selected?._id)
        .then(resp => {
          const data = resp
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          toast.error(err?.data)
        })
    } else {
      setup.addLeveLType(payload, token)
        .then(resp => {
          const data = resp
          setIsLoading(false)
          closeModal(true)
        }).catch(err => {
          setIsLoading(false)
          console.log(err)
          toast.error(err?.response?.data?.message)
        })

    }
  }
  return (
    <React.Fragment>
      <Modal show={props.showModal} onHide={() => closeModal()}>
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} Level Type
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="levelTypeName">
              <Form.Label>
                Level Type Name
              </Form.Label>
              <Form.Control
                placeholder="e.g Primary"
                defaultValue={props.selected?.levelTypeName}
                required
              />
            </Form.Group>

            <div className="mt-4 d-grid mb-5 px-5">
              <button
                className="btn btn-primary btn-block btn-lg rounded-0"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

AddEditLevelType.propTypes = {}

export default AddEditLevelType