import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Spinner } from "react-bootstrap"
import toast from "react-hot-toast";
import Select from "react-select"

import * as setup from "models/setup";
import * as lecture from "models/lectures"
import * as auth from "models/auth"

const AddEditLectures = props => {
  const [levelTypes, setLevelTypes] = useState([])
  const [levels, setLevels] = useState([])
  const [listtedSubject, setListedSubjects] = useState([])
  const [listedUsers, setListedUsers] = useState([])
  const [topics, setTopics] = useState([])
  const [subTopics, setSubTopics] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [selectedLevelType, setSelectedLevelType] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedSession, setSelectedSession] = useState(null)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [selectedTopic, setSelectedTopic] = useState(null)
  const [selectedSubTopic, setSelectedSubTopic] = useState(null)
  const [selectedInstructor, setSelectedInstructor] = useState(null)

  const sessions = [{
    label: "Sessions",
    options: [
      { label: "Term 1", value: "Term 1" },
      { label: "Term 2", value: "Term 2" },
      { label: "Term 3", value: "Term 3" }
    ]
  }
  ]

  const closeModal = () => {
    props.setShowModal(false)
    setIsLoading(false)
    props.setToEdit(false)
    props.setSelected(null)
  }


  const handleSelectLevelType = (selectedLevelType) => {
    setSelectedLevelType(selectedLevelType)
    //getAllSubCategories(selectedCategory?.label)
  }

  const handleSelectLevel = (selectedLevel) => {
    setSelectedLevel(selectedLevel)
  }
  const handleSelectSession = (selectedSession) => {
    setSelectedSession(selectedSession)
  }

  const handleSelectSubject = (selectedSubject) => {
    setSelectedSubject(selectedSubject)
  }
  const handleSelectTopic = (selectedTopic) => {
    setSelectedTopic(selectedTopic)
  }
  const handleSelectSubTopic = (selectedSubTopic) => {
    setSelectedSubTopic(selectedSubTopic)
  }
  const handleSelectInstructor = (selectedInstructor) => {
    setSelectedInstructor(selectedInstructor)
  }
  const handleLectures = (event) => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    setIsLoading(true)
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token
    console.log(form)

    const payload = {
      lectureName: form.lectureName.value,
      host: selectedInstructor?.value,
      subTopic: selectedSubTopic?.value,
      level: selectedLevel?.value,
      startTime: Date.now() + 12,
      duration: '45'

    }
    if (props.toEdit) {
      lecture.updateLecture(payload, token, props.selected?._id)
        .then(resp => {
          const data = resp
          closeModal()
        }).catch(err => {
          setIsLoading(false)
          toast.error(err?.data)
        })
    } else {
      lecture.addLectures(payload, token)
        .then(resp => {
          const data = resp
          setIsLoading(false)
          closeModal()
        }).catch(err => {
          setIsLoading(false)
          console.log(err)
          toast.error(err?.response?.data?.message)
        })

    }
  }

  function groupTopicsByUnit(topics) {
    const grouped = topics.reduce((acc, topic) => {
      // Find or create the unit entry in the accumulator array
      let unitEntry = acc.find(entry => entry.unit === topic.unit);

      // If the unit entry doesn't exist, create it
      if (!unitEntry) {
        unitEntry = { unit: topic.unit, unitId: topic.unitId, topics: [] };
        acc.push(unitEntry);
      }

      // Add the topic to the topics array of the found or created unit entry
      unitEntry.topics.push(topic);

      return acc;
    }, []);

    return grouped;
  }

  const getAllTopics = () => {
    setup.getAllTopics(`level=${selectedLevel?.value}`)
      .then(resp => {
        const data = groupTopicsByUnit(resp.topics)

        setListedSubjects([
          {
            label: "Learning Areaes",
            options: data.map(item => ({
              label: item?.unit,
              value: item?.unit,
              topics: item?.topics
            }))
          }])

      }).catch(err => {

      })
  }
  const getAllSubTopics = () => {
    setup.getAllSubTopics(`topic=${selectedTopic?.value}`)
      .then(resp => {
        const data = resp.subTopics
        setSubTopics([
          {
            label: "Sub Strands ",
            options: data.map(item => ({
              label: item?.subTopicName,
              value: item?.subTopicName,
            }))
          }])

      }).catch(err => {

      })
  }

  const getAllUsers = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    auth.getAllUsers(params, token)
      .then(resp => {
        const data = resp.users
        setListedUsers([
          {
            label: "Instructors ",
            options: data.map(item => ({
              label: item?.fullName,
              value: item?._id,
            }))
          }
        ])
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    if (selectedTopic) { getAllSubTopics() }
  }, [selectedTopic])

  useEffect(() => {
    setTopics([
      {
        label: "Strands ",
        options: selectedSubject?.topics.map(item => ({
          label: item?.topicName,
          value: item?.topicName,
        }))
      }
    ])
  }, [selectedSubject])
  useEffect(() => {
    setLevelTypes([
      {
        label: "Level Tpyes",
        options: props.levelTypes.map(item => ({
          label: item?.levelTypeName,
          value: item?.levelTypeName,
          levels: item?.levels
        }))
      }
    ])
  }, [props.levelTypes])

  useEffect(() => {
    console.log(selectedLevelType?.levels)
    setLevels([
      {
        label: "Level ",
        options: selectedLevelType?.levels.map(item => ({
          label: item?.levelName,
          value: item?.levelName,
        }))
      }
    ])
  }, [selectedLevelType])


  useEffect(() => {
    if (selectedLevel) {
      getAllTopics()
    }
  }, [selectedLevel])

  useEffect(() => {
    const query = `userType=instructor`
    getAllUsers(query)
  }, [])

  return (
    <React.Fragment>
      <Modal
        show={props.showModal}
        centered
        onHide={() => closeModal()}
      >
        <Modal.Header closeButton>
          {props.toEdit ? "Edit" : "Add"} Lectures
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleLectures}>
            <Form.Group className="mb-3" controlId="lectureName">
              <Form.Label>
                Lecture Name
              </Form.Label>
              <Form.Control
                placeholder="Enter Lecture Name"
                required

              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="levelType">
              <Form.Label>
                Level Type
              </Form.Label>
              <Select
                value={selectedLevelType}
                onChange={handleSelectLevelType}
                options={levelTypes}
              />
            </Form.Group>

            {selectedLevelType && (
              <Form.Group className="mb-3" controlId="county">
                <Form.Label>
                  Level
                </Form.Label>
                <Select
                  value={selectedLevel}
                  onChange={handleSelectLevel}
                  options={levels}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Sessions
              </Form.Label>
              <Select
                value={selectedSession}
                onChange={handleSelectSession}
                options={sessions}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Learning Area
              </Form.Label>
              <Select
                value={selectedSubject}
                onChange={handleSelectSubject}
                options={listtedSubject}
              />
            </Form.Group>
            {selectedSubject && (
              <Form.Group className="mb-3" controlId="county">
                <Form.Label>
                  Strand
                </Form.Label>
                <Select
                  value={selectedTopic}
                  onChange={handleSelectTopic}
                  options={topics}
                />
              </Form.Group>
            )}
            {selectedTopic && (
              <Form.Group className="mb-3" controlId="county">
                <Form.Label>
                  Sub Strand
                </Form.Label>
                <Select
                  value={selectedSubTopic}
                  onChange={handleSelectSubTopic}
                  options={subTopics}
                />
              </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="county">
              <Form.Label>
                Instructor
              </Form.Label>
              <Select
                value={selectedInstructor}
                onChange={handleSelectInstructor}
                options={listedUsers}
              />
            </Form.Group>
            <div className="mt-4 d-grid mb-5 px-5">
              <button
                className="btn btn-primary btn-block btn-lg rounded-0"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Processing...
                  </>
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment >
  )
}

AddEditLectures.propTypes = {}

export default AddEditLectures