import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import toast from "react-hot-toast";
import moment from "moment"
import { Card } from "react-bootstrap"

import TableTemplate from "components/TableTemplate"

import * as setup from "models/setup";

const Practicals = props => {
  const [lectures, setLectures] = useState()

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)
  const [levelTypes, setLevelTypes] = useState([""])
  const [showAddPractical, setShowAddPractical] = useState(false)
  const [showDeletePractical, setShowDeletePractical] = useState(false)

  const getAllPracticals = (params) => {

  }
  const getAllLevelTypes = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setup.getAllLevelTypes(params, token)
      .then(resp => {
        const data = resp.levelTypes
        setLevelTypes(data)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    getAllPracticals()
    getAllLevelTypes()
  }, [])

  return (
    <div className="page-content">
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title className="text-primary">
            Practicals
          </Card.Title>
          <button className="btn btn-primary"
            onClick={() => setShowAddPractical(true)}
          >
            Add Practicals
          </button>
        </Card.Header>
        <Card.Body>
          <TableTemplate />
        </Card.Body>
      </Card>



    </div>
  )
}

Practicals.propTypes = {}

export default Practicals