import PropTypes from "prop-types";
import React from "react";
import {
  Container,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import Overview from "./Overview";
import ExamInterface from "pages/ContentManagement/ExamsAndTests/ViewExam";
import CreateQuestionInterface from "pages/ContentManagement/ExamsAndTests/CreateTestAndExams";
import ScoreCard from "./ScoreCard";
const Dashboard = props => {

  //meta title
  document.title = "Dashboard | Tehuti Admin";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />
          {/* <ScoreCard />
          <Overview departments={[""]} dataColors='["#2D3194", "--bs-secondary" ]' /> */}
          {/* <ExamInterface /> */}
          {/* <CreateQuestionInterface /> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
