import React from 'react'
import PropTypes from 'prop-types'
import { Card, Modal } from "react-bootstrap"

import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import moment from "moment"

const ViewContent = props => {

  const zoomPluginInstance = zoomPlugin();
  const { Zoom, ZoomInButton, ZoomOutButton, zoomTo } = zoomPluginInstance;

  const closeModal = () => {
    props.setShowModal(false)
    props.setSelected(null)
  }

  return (
    <Modal show={props.showModal} onHide={() => closeModal()}>

      <Modal.Body closeButton>
        <div className=" bg-white ">

          <div className="  justify-content-between align-items-center bg-light px-2  ">
            {props?.selected?.fileType === "pdf" ?
              <a className="" >

                <Card
                >
                  <Card.Header className="d-flex bg-white justify-content-evenly" >
                    <div className="d-flex flex-column w-100 justify-content-between">


                      <div className="me-2  d-flex  justify-content-end">
                        <div className="d-none d-md-flex justify-content-center mt-2" style={{ backgroundColor: '' }}>
                          {/* <ZoomInButton />
                          <Zoom />
                          <ZoomOutButton /> */}
                        </div>
                        <button className="btn" onClick={() => printPDF(props.selected?.filePath)}>

                          <i className="   fas fa-print me-2" aria-hidden="true"></i> </button>

                        <a className="btn " href={props?.selected?.filePath} download={props?.selected?.title}>
                          <i className="fas fa-download" aria-hidden="true"></i>
                          {/* <p className="ms-2 "> Download</p> */}
                        </a>
                        <div className="btn fs-bold"
                          onClick={() => {
                            props.setFileViewer(false)
                          }} >
                          <i className="   dripicons-cross me-2" aria-hidden="true"></i>
                        </div>
                      </div>
                      <Card.Title className="text-centered"> {props?.selected?.title}</Card.Title>

                    </div>


                  </Card.Header>
                  <div className="overflow-y-auto pb-5 mx-1 vh-100">
                    <Viewer
                      fileUrl={props?.selected?.filePath}
                      theme={{
                        theme: "Light"
                      }}

                      plugins={[zoomPluginInstance]}
                    />
                  </div>
                </Card>



              </a>
              : props?.selected?.fileType === "audio" ? <Card>
                <Card.Header className="bg-white p-0">
                  <div className="d-flex flex-column w-100 justify-content-between">
                    <div className="me-2  d-flex  justify-content-end">
                      <div className="btn fs-bold"
                        onClick={() => {
                          props.setFileViewer(false)
                        }} >
                        <i className="   dripicons-cross me-2" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="d-flex align-items-center justify-content-center py-5 mb-2">
                  <audio
                    className="props?.selected-image bg-light"
                    controls
                    src={props?.selected?.filePath}
                    type="audio/ogg"
                  />
                </Card.Body>
              </Card> : props?.selected?.fileType === "video" ?
                <Card>
                  <Card.Header className="bg-white p-0">
                    <div className="d-flex flex-column w-100 justify-content-between">
                      <div className="me-2  d-flex  justify-content-end">
                        <div className="btn fs-bold"
                          onClick={() => {
                            props.setFileViewer(false)
                          }} >
                          <i className="   dripicons-cross me-2" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </Card.Header>
                  <video
                    className="item-image px-2"
                    controls
                    src={props?.selected?.filePath}
                    type="video/mp4"


                  />
                  <div className="px-2 py-2">

                    <ul className='list-inline mb-0 font-size-12 d-flex flex-column '>
                      <li className="list-inline-props?.selected text-black   ">

                        <b>  {props?.selected?.title}</b>
                      </li>
                      <li className="list-inline-item text-black  font-size-10 ">


                        {moment(props?.selected?.created).endOf('day').fromNow()}
                      </li>
                    </ul>

                  </div>
                </Card>
                : null}

          </div>

        </div>
      </Modal.Body>
    </Modal>
  )
}

ViewContent.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
}

export default ViewContent