import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

//Post Funtions
export const addLectures = async (payload, token) => {
  return await axios
    .post(getApi.LECTURES.ADD_LECTURE, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const uploadFile = async (formData, fileId, token) => {
  return await axios
    .post(`${getApi.FILES.UPLOAD_FILE}/${fileId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },

      onUploadProgress: (progressEvent) => {
        const percentComplete = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(`Upload progress: ${percentComplete}%`);
        // You can update your progress bar or UI here
      },

    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//get Funtions
export const getLectureById = async (id) => {
  return await axios
    .get(`${getApi.LECTURES.GET_LECTURE_BY_ID}/${id}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getAllLectures = async (params) => {
  return await axios
    .get(`${getApi.LECTURES.GET_ALL_LECTURES}?${params === undefined ? "" : params}`,)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const updateLecture = async (payload, token, lectureId) => {
  return await axios
    .put(`${getApi.LECTURES.UPDATE_LECTURE}/${lectureId}`, payload, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//Delete Funtions

export const deleteLecture = async (lectureId, token) => {
  return await axios
    .delete(`${getApi.LECTURES.DELETE_LECTURE}/${lectureId}`, {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err.message))
}
