import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, Col, Container, Row } from "react-bootstrap"
import moment from "moment"
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import TableTemplate from "components/TableTemplate"

import * as setup from "models/setup";
import AddEditSubTopic from "./AddEditSubTopic";
import DeleteSubTopic from "./DeleteSubTopic";
const SubTopics = props => {
  const [subTopics, setSubTopics] = useState([])

  const [isLoaded, setIsLoaded] = useState(false)
  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)

  const [showAddEditSubTopic, setShowAddEditSubTopic] = useState(false)
  const [showDeleteSubTopic, setShowDeleteSubTopic] = useState(false)

  const {
    match: { params },
  } = props

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <button
              className="btn btn-sm text-info text-nowrap "
              onClick={() => {
                setShowAddEditSubTopic(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteSubTopic(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },
    { name: "Topic Name", selector: row => row?.subTopicName },
    { name: "Created", selector: row => moment(row.created).format('LL'), }


  ]
  const subHeaderNav = (
    <div className="d-flex w-100 justify-content-between">
      <div className="d-flex align-items-center">
        <h5 className="text-primary">Sub Strands</h5>
      </div>
      <button className="btn btn-primary"
        onClick={() => setShowAddEditSubTopic(true)}>
        Add Sub Strand
      </button>
    </div>
  )
  const getAllSubTopics = () => {
    setup.getAllSubTopics(`topic=${params?.topicName}`)
      .then(resp => {
        const data = resp.subTopics
        setSubTopics(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    getAllSubTopics()
  }, [])
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <Card.Header >
                <Card.Title className="text-primary"><Link
                  to={`/setup/learning-areas`}>
                  Learning Areas
                </Link> | <Link
                  to={`/setup/learning-area/${params?.unitName}`}>
                    {params?.unitName}
                  </Link> | {params?.topicName}</Card.Title>
              </Card.Header>
              <Card.Body>
                <TableTemplate
                  dataLoaded={isLoaded}
                  subHeaderNav={subHeaderNav}
                  columns={columns}
                  rows={subTopics}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <AddEditSubTopic
          showModal={showAddEditSubTopic}
          setShowModal={setShowAddEditSubTopic}
          toEdit={toEdit}
          setToEdit={setToEdit}
          selected={selected}
          setSelected={setSelected}
          topic={params?.topicName}
          fetchData={getAllSubTopics}
        />

        <DeleteSubTopic
          showModal={showDeleteSubTopic}
          setShowModal={setShowDeleteSubTopic}
          selected={selected}
          setSelected={setSelected}
          fetchData={getAllSubTopics}
        />
      </Container>
    </div>
  )
}

SubTopics.propTypes = {}

export default SubTopics